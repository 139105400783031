@import "./../../../variables";
.swal2-container {
  z-index: 9999 !important;
}

#orders-modal {
  @apply p-1 lg:p-0;
  height: 100vh;
  max-width: 1100px;
  margin: auto;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (min-width: $xl) {
    max-height: 750px;
  }
}
#orders-modal-page {
  .swal2-container {
    .swal2-modal {
      background: rgba(255, 255, 255, 0);
      img {
        margin: auto;
      }
    }
  }
  width: 100%;
  height: 100%;
  .text-field-custom {
    @apply mb-1 mx-0 w-full;
    input {
      font-weight: 300;
    }
  }

  .card-control {
    margin: 0 auto;
    height: 100%;

    @media screen and (max-width: $sm) {
      .card-head {
        gap: 1rem;
      }
    }
    .card-head {
      @media screen and (max-width: 440px) {
        .head-action {
          gap: 1rem !important;
          flex-direction: column;
        }
      }
      .head-action {
        display: flex;
        align-items: baseline;
        gap: 2rem;
        .head-title {
          font-weight: 400;
        }
        .status {
          &.pending {
            @apply bg-yellow-500;
          }
          &.complete {
            color: green;
          }
          &.waiting {
            color: blue;
          }
          &.error {
            color: red;
          }
        }
      }
      .status {
        .btn {
          @apply bg-amber-400 hover:bg-amber-400;
        }
      }
    }
    .card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      @media screen and (max-width: $sm) {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding: 10px;
            font-size: 12px;
          }
        }
        p {
          font-size: 14px;
          &.location {
            font-size: 14px;
          }
        }
        label {
          font-size: 14px;
        }
      }
      @media screen and (max-width: $xs) {
        p {
          font-size: 12px;
          &.location {
            font-size: 12px;
          }
        }
        label {
          font-size: 14px;
        }
      }
      .box-top {
        .MuiTableCell-root {
          border-bottom: 0;
          width: 25%;
          .pickup-image {
            @apply m-auto;
            p {
              color: rgb(68, 68, 68);
            }
            label {
              align-items: center;
              gap: 5px;
              display: inline-flex;
              color: rgb(255, 0, 0);
              &:hover {
                color: rgba(94, 94, 94, 0.822);
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
          .delivery-location {
            @apply m-auto;
            p {
              color: rgb(68, 68, 68);
            }
            label {
              align-items: center;
              gap: 5px;
              display: inline-flex;
              color: rgb(255, 0, 0);
              &:hover {
                color: rgba(94, 94, 94, 0.822);
                cursor: pointer;
                text-decoration: underline;
              }
            }
            iframe {
              width: 100%;
            }
          }
        }
        @media screen and (max-width: $sm) {
          .box-rows {
            flex-wrap: wrap;
            .column-top {
              width: 50% !important;
            }
            .colomn-image {
              margin-top: 1rem;
              width: 100% !important;
              color: rgb(131, 131, 131);
            }
          }
        }
        .box-rows {
          @apply flex justify-around;
          &.foods {
            justify-content: left;
          }
          .column-top {
            @apply w-1/4 mt-4;
            color: rgb(131, 131, 131);
            p {
              color: rgb(68, 68, 68);
            }
            figure {
              margin-top: 0.5rem;
              max-width: 7rem;
              cursor: pointer;
            }
            label.location {
              cursor: pointer;
              color: red;
            }
            p.location {
              padding-right: 0.5rem;
              margin-bottom: 1rem;
            }
          }
          .colomn-image {
            margin-top: 1rem;
            width: 100% !important;
            color: rgb(131, 131, 131);
          }
        }
      }
      .product-image {
        figure {
          max-width: 5rem;
          img {
            margin: auto;
          }
        }
      }
    }
  }

  .btn-action {
    @apply flex justify-end gap-x-3 pt-4 border-t xl:mt-auto;
  }
}

figure.preview-img-confirm {
  width: 20rem;
  height: 20rem;
  margin: auto;
  position: relative;
  overflow: hidden;
}
figure.preview-img-confirm {
  width: 10rem;
  height: 10rem;
  margin: auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &.error {
    border: 2px solid red;
  }
  &:hover {
    svg {
      opacity: 0.4;
    }
    .circle {
      opacity: 1;
    }
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }
  svg {
    position: absolute;
    width: 2rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
  }
  .circle {
    width: 2rem;
    height: 2rem;
    border: 1px solid #838383;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 1.7rem;
    border-radius: 10rem;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
  }
}

.dialog {
  .MuiPaper-root {
    width: 100% !important;
    height: 100% !important;
    max-height: 210px;
    max-width: 200px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 400;
    }

    figure.preview-img-confirm {
      width: 20rem;
      height: 20rem;
      margin-top: 1rem;
      margin-inline: auto;
      position: relative;
      overflow: hidden;
    }
    figure.preview-img-confirm {
      width: 10rem;
      height: 10rem;
      margin-top: 1rem;
      margin-inline: auto;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &.error {
        border: 2px solid red;
      }
      &:hover {
        svg {
          opacity: 0.4;
        }
        .circle {
          opacity: 1;
        }
      }
      input {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
      }
      svg {
        position: absolute;
        width: 2rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: opacity 0.5s;
        pointer-events: none;
      }
      .circle {
        width: 2rem;
        height: 2rem;
        border: 1px solid #838383;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 1.7rem;
        border-radius: 10rem;
        opacity: 0;
        transition: opacity 0.5s;
        pointer-events: none;
      }
    }

    .details {
      margin-top: 1rem;
      margin-inline: auto;
      .MuiFormControl-root {
        width: 10rem;
      }
    }

    .btn {
      margin-top: 2rem;
      text-align: center;
    }
  }
}
