@import "./../../../variables";
#member-modal {
  @apply p-1 lg:p-0;
  height: 480px;
  max-width: 450px;
  margin: auto;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#member-page {
  width: 100%;
  height: 100%;
  .text-field-custom {
    @apply mb-1 mx-0 w-full;
    input {
      font-weight: 300;
    }
  }

  .card-control {
    margin: 0 auto;
    height: 100%;

    .card-head {
      .head-action {
        .head-title {
          font-weight: 400;
        }
      }
    }
    .card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      form {
        .image-setting {
          @apply flex gap-x-4 w-full xl:w-2/3 mx-auto;

          .add-image {
            margin: auto 0;
          }
          .image-detail {
            margin-right: auto;
          }
          &.error {
            .image-upload {
              @apply shadow-md border-2 shadow-red-400 border-red-400;
            }
          }
        }
        .member-details {
          @apply flex flex-wrap justify-between xl:justify-around my-3 2xl:gap-y-1;
          .member-detail-title {
            font-weight: 400;
            width: 100%;
            flex: none;
          }
          .input-full {
            @apply w-full;
            display: flex;
            align-items: center;
            // height: 52px;
            width: 100%;
            .group {
              @apply lg:px-2 text-center mt-6;
              max-width: 24%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: auto;
            }
          }
          .center {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }
          .form-control {
            @apply w-full;
          }
          .input-group {
            @apply flex items-center w-full xs:w-1/2 border border-gray-300 mt-6;
            max-width: 49%;
            border-radius: 5px;
            justify-content: space-between;
            padding: 8px 0.5rem;
            .title {
              @apply w-24 text-center;
              user-select: none;
            }
            button {
              height: 1.5rem;
            }
          }
          .group {
            @apply lg:px-2 text-center mt-6;
            max-width: 49%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
          }
        }
      }
    }
  }

  .btn-action {
    @apply flex justify-end gap-x-3 pt-4 border-t mt-auto;
  }
}
