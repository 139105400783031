@import "../../variables";


#members-page {
  .card-head {
    border: 0;
    display: flex;
    h2 {
      align-self: flex-end;
      line-height: 35px;
    }
    .head-action {
      @apply w-full;
      display: flex;
      gap: 0.75rem;
      flex-wrap: wrap;

      @media screen and (min-width: $md) {
        flex-direction: row;
      }

      .slc-members-status {
        @apply w-full;
        min-width: 200px;
      }
      .searchpage {
        @apply w-full md:w-auto order-3 md:order-1;
        min-width: 200px;
        height: 100%;
      }
      .input-search {
        @apply border border-gray-400 rounded px-2 focus:outline-blue-400;
      }
      button {
        margin-top: auto;
      }
      .btn-add-members {
        @apply ml-auto order-2 md:order-2;
      }

      .MuiFormControl-root {
        @apply w-full md:w-auto ml-auto;
        min-width: 200px;
        height: 100%;
      }
    }
  }

  .members-tab-section {
    .tab-header {
      .MuiTabs-scroller {
        overflow: auto !important;
        &::-webkit-scrollbar {
          height: 3px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          @apply bg-gray-200;
        }
      }
      .members-tab-head-field {
        @apply p-6 flex flex-row gap-2;
        svg {
          @apply my-auto;
        }
      }
    }
    .members-tab-body {
      min-height: 384px;
      padding: 0.75rem;
      &[hidden] {
        padding: 0;
        min-height: 0;
      }

      .item-list {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr;
        .column-action {
          .btn-custom {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}